import AvField from "availity-reactstrap-validation/lib/AvField"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Label, Row, Button } from "reactstrap"
import Select from "react-select"
import API from "../../api"
import { Redirect, withRouter } from "react-router-dom"
import PropTypes from "prop-types"
import cresusFond from "../../assets/images/cresuFond.jpg"
// import cresusLogin from "../../assets/images/banner_login.jpg"
import axios from "axios"
// Toastify
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Flip } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"

const Register = props => {
  const [nom, setNom] = useState("")
  const [verifier, setVerifier] = useState(false)
  const [adresse, setAdresse] = useState("")
  const [telephone, setTelephone] = useState("")
  const [mobile, setMobile] = useState("")
  const [email, setEmail] = useState("")
  const [nom_gerant, setGerant] = useState("")
  const [matricule, setMatricule] = useState("")
  const [display, setDisplay] = useState(0)

  const save = async () => {
    if (
      nom != "" &&
      nom_gerant != "" &&
      matricule != "" &&
      email != "" &&
      adresse != "" &&
      mobile != "" &&
      telephone != ""
    ) {
      const res = await axios.post(
        "https://stages.backcresus-institut.ovh/api/entreprise/add",
        {
          nom_entreprise: nom,
          nom_gerant: nom_gerant,
          matricule: matricule,
          email: email,
          adresse: adresse,
          mobile: mobile,
          telephone: telephone,
        }
      )
      if (res.data.status === 200) {
        setDisplay(1)
        const timer = setTimeout(() => {
          props.history.push("/login")
        }, 2000)
      } else {
        toast.error("⛔ Veuillez vérifier votre email et mot de passe !", {
          containerId: "A",
        })
      }
    } else {
      toast.error("⛔ Remplir les champs vides!", {
        containerId: "A",
      })
    }
  }

  return (
    <div
      style={{
        backgroundImage: `url(${cresusFond})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <div style={{ paddingTop: "7%" }}>
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              {display === 0 ? (
                <Card className="overflow-hidden">
                  <div>
                    {" "}
                    <div>
                      <Row>
                        <Col className="justify-content-center pt-4">
                          <h1
                            style={{
                              color: "#485ec4",
                              textAlign: "center",
                            }}
                          >
                            Création d&apos;un compte
                          </h1>
                        </Col>
                      </Row>
                    </div>
                    <CardBody className="pt-4">
                      <div className="p-2">
                        <AvForm className="form-horizontal">
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="nom"
                                  className="form-control"
                                  placeholder="Entreprise"
                                  errorMessage="* Entreprise obligatoire"
                                  required
                                  onChange={e => setNom(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="gerant"
                                  type="text"
                                  required
                                  placeholder="Gérant"
                                  errorMessage="* Gérant obligatoire"
                                  onChange={e => setGerant(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="telephone"
                                  type="telephone"
                                  placeholder="Téléphone"
                                  errorMessage="* Téléphone obligatoire"
                                  required
                                  onChange={e => setTelephone(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="Téléphone fixe"
                                  type="mobile"
                                  required
                                  placeholder="Téléphone fixe"
                                  errorMessage="* Téléphone fixe obligatoire"
                                  onChange={e => setMobile(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="matricule"
                                  type="matricule"
                                  required
                                  placeholder="Matricule"
                                  errorMessage="* Matricule obligatoire"
                                  onChange={e => setMatricule(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="adresse"
                                  type="text"
                                  required
                                  placeholder="Adresse"
                                  errorMessage="* Adresse obligatoire"
                                  onChange={e => setAdresse(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <AvField
                                  name="E-mail"
                                  type="email"
                                  required
                                  placeholder="E-mail"
                                  errorMessage="* E-mail obligatoire"
                                  onChange={e => setEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="mb-3">
                            <Label for="basicpill-firstname-input1"></Label>
                            <ReCAPTCHA
                              id="cap"
                              sitekey="6Lebx24fAAAAABJtE8xEeUBYfb5MrDCSyjBL2J3u"
                              onChange={() => setVerifier(true)}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <Col lg="6">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                              >
                                <Button
                                  type="button"
                                  className="btn btn-warning mb-2 me-2"
                                  onClick={() => props.history.push("/login")}
                                >
                                  Annuler
                                </Button>
                                <Button
                                  id="Confirmer"
                                  name="Confirmer"
                                  type="button"
                                  color="primary"
                                  className="btn btn-primary mb-2 me-2"
                                  disabled={!verifier}
                                  onClick={save}
                                >
                                  Confirmer
                                </Button>
                              </div>
                            </Col>
                          </div>
                        </AvForm>
                      </div>
                    </CardBody>
                    <div className="mt-1 text-center">
                      <p>
                        © {new Date().getFullYear()} Cresus. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by{" "}
                        <a href="https://cresus.pro/">cresus.pro</a>
                      </p>
                    </div>
                  </div>
                </Card>
              ) : (
                <div className="alert alert-success" role="alert">
                  <h4 className="alert-heading">Succès!!</h4>
                  <p className="mb-0">Merci pour votre inscription.</p>
                  <p></p>
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <ToastContainer
          transition={Flip}
          enableMultiContainer
          containerId={"A"}
          position={toast.POSITION.TOP_RIGHT}
          autoClose={2500}
        />
      </div>
    </div>
  )
}

export default withRouter(Register)

Register.propTypes = {
  history: PropTypes.object,
}
